.tree-arrow-wrapper {
    position: relative; 
    width: 24px;
    --tree-color: rgba(var(--color-read-only));
    opacity: var(--color-read-only-alpha);
}

.tree-arrow-line {
    width: 50%; 
    height: 50%; 
    border-left: 1px solid var(--tree-color);
    border-bottom: 1px solid var(--tree-color); 
    right: 1px;
    top: 0%;
    position: absolute;
    border-bottom-left-radius: 7px;
}

.tree-arrow-tip {
    width: 5px;
    height: 1px;
    border-radius: 4px;
    background-color: var(--tree-color);
    position: absolute;
    bottom: -1px;
    right: -1px;
    /* transform: ; */
    transform-origin: right;
}

.tree-arrow-left-pointer {
    width: 5px;
    height: 1px;
    border-radius: 4px;
    background-color: var(--tree-color);
    position: absolute;
    bottom: -1px;
    right: -1px;
    transform: rotate(35deg);
    transform-origin: right;
}

.tree-arrow-right-pointer {
    width: 5px;
    height: 1px;
    border-radius: 4px;
    background-color: var(--tree-color);
    position: absolute;
    bottom: -1px;
    right: -1px;
    transform: rotate(-35deg);
    transform-origin: right;
}

.tree-arrow-bar {
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: var(--tree-color);
    right: 50%;
}